import { Box, ModalHeader } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Input from '@components/Input';
import Typography from '@components/Typography';
import useMutation from '@hooks/fetching/useMutation';
import { CloseLarge16 } from '@icons/index';
import capitalize from 'lodash/capitalize';
import { useCallback, useEffect, useState } from 'react';
import Button from 'src/common/components/Button';
import { FolderData } from 'src/common/components/LeftSidePanelV2/DashboardFolder/UpsertFolderModal';
import { FolderSelect } from 'src/common/components/FolderSelect';
import Modal from 'src/common/components/Modal';
import ModalFooter from 'src/common/components/ModalFooter';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { TestIDs } from 'src/common/types/test-ids';
import { GetCollectionsSubscription } from 'src/generated/graphql';
import DescriptionField from 'src/pages/OntologyPage/components/DescriptionField';
import { InsertCollectionAndFeed, UpdateCollection } from 'src/queries/collections';
import { useReportEvent } from 'src/services/analytics';
import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { DEFAULT_EMOJIPICKER_VALUE } from 'src/common/components/EmojiPicker/EmojiPicker';

type OnCloseArgs = { name: string; id?: string };

type CollectionUpsertModalProps = {
	isOpen: boolean;
	onClose: (params?: OnCloseArgs) => void;
	collectionType: string;
	collection?: GetCollectionsSubscription['workspaces'][0];
	stopRedirect?: boolean;
	folderId?: string;
};

export type SpaceValue = {
	spaceName: string;
	spaceDescription: string;
	folder_id?: string;
};

const duplicateNameError =
	'Uniqueness violation. duplicate key value violates unique constraint "workspaces_tid_name_collection_type_key"';

const MODAL_Z_INDEX = 'var(--chakra-zIndices-popover)';

// TODO: change the terminology to collection (no space!)

export function CollectionUpsertModal({
	isOpen,
	onClose,
	collectionType,
	collection,
	stopRedirect,
	folderId,
}: CollectionUpsertModalProps) {
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');
	const modalState = collection ? 'Edit' : 'Create';
	const { reportEvent } = useReportEvent({ itemType: 'dashboard', flow: modalState, feature: 'Sidebar' });
	const [spaceValue, setSpaceValue] = useState<SpaceValue>({
		spaceName: collection?.name || '',
		spaceDescription: collection?.description || '',
		folder_id: collection?.folder_id || folderId || '',
	});
	const [folderData, setFolderData] = useState<FolderData>({ emoji: DEFAULT_EMOJIPICKER_VALUE, folderName: '' });

	const { spaceName, spaceDescription, folder_id } = spaceValue;

	const { emoji, folderName } = folderData;

	const [error, setError] = useState('');
	const isError = !!error;
	const submitMessage = collection ? 'Save' : 'Create';
	const collectionLabel = capitalize(collectionType); // todo: this is too specific for workpspace and dashboards
	const [collectionMutate, { error: mutationError, loading: isLoading }] = useMutation(
		collection ? UpdateCollection : InsertCollectionAndFeed
	);
	const { createDashboardFolder, isCreateFolderLoading } = useDashboardFolders();

	const isFolderFieldVisible = isDashboardFoldersEnable && collectionType === 'dashboard';
	const isNotLoading = !isLoading && !isCreateFolderLoading;
	const isFolderValid = isFolderFieldVisible ? !!folder_id || (!!emoji && !!folderName.trim()) : true;
	const isPrimaryEnabled = isNotLoading && !!spaceName.trim() && isFolderValid;

	const setInitialSpaceValue = useCallback(
		() =>
			setSpaceValue((prevValue) => ({
				...prevValue,
				spaceName: collection?.name || '',
				spaceDescription: collection?.description || '',
				folder_id: collection?.folder_id || folderId || '',
			})),
		[collection?.description, collection?.folder_id, collection?.name, folderId]
	);

	useEffect(() => setInitialSpaceValue(), [setInitialSpaceValue]);

	const onModalClose = (params?: OnCloseArgs) => {
		onClose(params);
		setInitialSpaceValue();
		setFolderData({ emoji: DEFAULT_EMOJIPICKER_VALUE, folderName: '' });
		setError('');
	};

	const isFormValid = () => {
		const isSpaceNameValid = !spaceName;

		if (isSpaceNameValid) {
			setError(`Please choose a name for your ${collectionLabel}`);
		}

		return !isSpaceNameValid;
	};

	const asyncErrorHandler = () => {
		if (!mutationError?.message) return;

		switch (mutationError.message) {
			case duplicateNameError:
				setError(`A ${collectionLabel} with this name already exists, please choose a different name`);
				break;
			default:
				// TODO: add a 'critical' level log
				setError('Error occurred');
		}
	};

	useEffect(asyncErrorHandler, [mutationError, collectionLabel]);

	const { navigate } = useNavigation();

	async function submit() {
		const isValid = isFormValid();

		if (!isValid) return;

		reportEvent({ event: 'sidebar-create-new-item-create', metaData: { itemType: 'dashboard' } });

		if (folder_id) {
			await onSubmitRequests();
		} else {
			await createDashboardFolder(folderData.folderName, folderData.emoji, false).then((res) =>
				onSubmitRequests(res?.insert_folders_one?.id)
			);
		}
	}

	const onSubmitRequests = async (newFolderId?: string) => {
		const folderId = newFolderId || folder_id;
		if (!collection) {
			await collectionMutate({
				variables: {
					name: spaceName,
					description: spaceDescription,
					collection_type: collectionType,
					folder_id: folderId,
				},
			}).then((res) => {
				const collectionId = res?.data?.insert_workspaces_one?.id;
				if (!stopRedirect) navigate({ path: `/${collectionType}/${collectionId}` });
				onModalClose({ name: spaceName, id: collectionId });
			});
		} else {
			await collectionMutate({
				variables: {
					id: collection.id,
					name: spaceName,
					order: collection.order,
					description: spaceDescription,
					folder_id: folderId,
				},
			}).then(() => {
				onModalClose({ name: spaceName });
			});
		}
	};

	const FolderField = (
		<>
			<FolderSelect
				folderId={folder_id}
				setFolderId={(folder_id: string) =>
					setSpaceValue((prevValue) => ({
						...prevValue,
						folder_id,
					}))
				}
				isInitSelect
				setFolderData={(folderData) => {
					setSpaceValue((prevValue) => ({
						...prevValue,
						folder_id: '',
					}));
					setFolderData(folderData);
				}}
			/>
		</>
	);

	return (
		<Modal
			maxWidth={'460px'}
			zIndex={MODAL_Z_INDEX}
			isOpen={isOpen}
			onClose={onModalClose}
			closeOnOverlayClick={false}
			isCentered
		>
			<ModalHeader p="16px" boxShadow={shadows.borderBottom}>
				<Flex justifyContent="space-between" alignItems="center">
					<Button isIconOnly variant="outline" onClick={() => onModalClose()} size="inline" colorScheme="black">
						<CloseLarge16 color={colors.gray[900]} />
					</Button>
					<Box margin={'0 auto'}>
						<Typography color={'gray.1000'} marginLeft={'-40px'} variant="DesktopH7Medium">
							{modalState} {collectionType?.toLowerCase()}
						</Typography>
					</Box>
				</Flex>
			</ModalHeader>
			<Flex flexDirection={'column'} padding="24px" gap={'24px'}>
				<Flex flexDirection={'column'} gap={'12px'}>
					<Typography variant={'DesktopH10Regular'} color={'gray.1000'}>
						{`${collectionLabel} name`}
					</Typography>
					<Input
						autoFocus
						size="md"
						isInvalid={isError}
						fontSize={'14px'}
						placeholder={'Add name'}
						errorMessage={error}
						onBlur={() =>
							reportEvent({
								event: 'sidebar-dashboard-modal-input-provided',
								metaData: { field: 'name', input: spaceName },
							})
						}
						onChange={(spaceName: string) => setSpaceValue((prevValue) => ({ ...prevValue, spaceName }))}
						value={spaceName}
						onEnter={submit}
						data-testid={TestIDs.NEW_COLLECTION_NAME_INPUT(collectionType)}
					/>
				</Flex>
				<Flex flexDirection={'column'} gap={'12px'}>
					<DescriptionField
						placeholder={`Describe this ${collectionType?.toLowerCase()}`}
						value={spaceDescription || ''}
						onBlur={() =>
							reportEvent({
								event: 'sidebar-dashboard-modal-input-provided',
								metaData: { field: 'description', input: spaceDescription },
							})
						}
						onChange={(spaceDescription: string) => {
							setSpaceValue((prevValue) => ({ ...prevValue, spaceDescription }));
						}}
					/>
				</Flex>
				{isFolderFieldVisible && FolderField}
			</Flex>
			<Box boxShadow={shadows.borderTop}>
				<ModalFooter
					size={'lg'}
					primaryButtonLabel={submitMessage}
					isPrimaryLoading={isLoading}
					isPrimaryEnabled={isPrimaryEnabled}
					onPrimaryClick={submit}
					onCancel={() => onModalClose()}
					color={isPrimaryEnabled ? 'blue' : 'gray'}
					cancelButtonColor={'gray'}
					testId={TestIDs.NEW_COLLECTION_MODAL_FOOTER(collectionType)}
				/>
			</Box>
		</Modal>
	);
}
